.tableHeaders {
  background-color: rgb(240, 238, 238);
}

.tableRowData:hover {
  background-color: #047cc0;
  color: white !important;
}

.rowIcon {
  visibility: hidden;
}

.tableRowData:hover .rowIcon {
  visibility: visible !important;
}

.TableRowMenuItems {
  padding: 10px;
  cursor: pointer;
  margin-left: -40px;
  color: black;
}

.TableRowMenuItems:hover {
  background-color: lightgrey;
}

.overFlowDescription {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
}
