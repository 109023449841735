.J1title .react-flow__handle-bottom,
.I1title .react-flow__handle-bottom,
.H1title .react-flow__handle-bottom,
.G1title .react-flow__handle-bottom,
.F1title .react-flow__handle-bottom,
.E1title .react-flow__handle-bottom,
.D1title .react-flow__handle-bottom,
.C1title .react-flow__handle-bottom,
.B1title .react-flow__handle-bottom,
.A1title .react-flow__handle-bottom,
.group3 .react-flow__handle-bottom,
.group2 .react-flow__handle-bottom,
.group1 .react-flow__handle-bottom,
.PMDDTitle .react-flow__handle-top,
.PMDDTitle .react-flow__handle-bottom,
.MSATitle .react-flow__handle-top,
.MSATitle .react-flow__handle-bottom,
.TMRRTitle .react-flow__handle-top,
.TMRRTitle .react-flow__handle-bottom,
.EMDTitle .react-flow__handle-top,
.EMDTitle .react-flow__handle-bottom,
/* .EMDSCMPDTitle .react-flow__handle-top,
.EMDSCMPDTitle .react-flow__handle-bottom, */
.PDTitle .react-flow__handle-top,
.PDTitle .react-flow__handle-bottom,
.OSTitle .react-flow__handle-top,
.OSTitle .react-flow__handle-bottom,
.PMDDTitle2 .react-flow__handle-top,
.PMDDTitle2 .react-flow__handle-bottom,
.MSATitle2 .react-flow__handle-top,
.MSATitle2 .react-flow__handle-bottom,
.TMRRTitle2 .react-flow__handle-top,
.TMRRTitle2 .react-flow__handle-bottom,
.EMDTitle2 .react-flow__handle-top,
.EMDTitle2 .react-flow__handle-bottom,
/* .EMDSCMPDTitle2 .react-flow__handle-top,
.EMDSCMPDTitle2 .react-flow__handle-bottom, */
.OSTitle .react-flow__handle-top,
.OSTitle .react-flow__handle-bottom,
.J2title .react-flow__handle-bottom,
.I2title .react-flow__handle-bottom,
.H2title .react-flow__handle-bottom,
.G2title .react-flow__handle-bottom,
.F2title .react-flow__handle-bottom,
.E2title .react-flow__handle-bottom,
.D2title .react-flow__handle-bottom,
.C2title .react-flow__handle-bottom,
.B2title .react-flow__handle-bottom,
.A2title .react-flow__handle-bottom,
.J3title .react-flow__handle-bottom,
.I3title .react-flow__handle-bottom,
.H3title .react-flow__handle-bottom,
.G3title .react-flow__handle-bottom,
.F3title .react-flow__handle-bottom,
.E3title .react-flow__handle-bottom,
.D3title .react-flow__handle-bottom,
.C3title .react-flow__handle-bottom,
.B3title .react-flow__handle-bottom,
.A3title .react-flow__handle-bottom,
.J4title .react-flow__handle-bottom,
.I4title .react-flow__handle-bottom,
.H4title .react-flow__handle-bottom,
.G4title .react-flow__handle-bottom,
.F4title .react-flow__handle-bottom,
.E4title .react-flow__handle-bottom,
.D4title .react-flow__handle-bottom,
.C4title .react-flow__handle-bottom,
.B4title .react-flow__handle-bottom,
.A4title .react-flow__handle-bottom,
.J5title .react-flow__handle-bottom,
.I5title .react-flow__handle-bottom,
.H5title .react-flow__handle-bottom,
.G5title .react-flow__handle-bottom,
.F5title .react-flow__handle-bottom,
.E5title .react-flow__handle-bottom,
.D5title .react-flow__handle-bottom,
.C5title .react-flow__handle-bottom,
.B5title .react-flow__handle-bottom,
.A5title .react-flow__handle-bottom,
.J6title .react-flow__handle-bottom,
.I6title .react-flow__handle-bottom,
.H6title .react-flow__handle-bottom,
.G6title .react-flow__handle-bottom,
.F6title .react-flow__handle-bottom,
.E6title .react-flow__handle-bottom,
.D6title .react-flow__handle-bottom,
.C6title .react-flow__handle-bottom,
.B6title .react-flow__handle-bottom,
.A6title .react-flow__handle-bottom,
.J7title .react-flow__handle-bottom,
/* .Border7 .react-flow__handle-bottom,
.I7title .react-flow__handle-bottom,
.H7title .react-flow__handle-bottom,
.G7title .react-flow__handle-bottom,
.F7title .react-flow__handle-bottom,
.E7title .react-flow__handle-bottom,
.D7title .react-flow__handle-bottom,
.C7title .react-flow__handle-bottom,
.B7title .react-flow__handle-bottom,
.A7title .react-flow__handle-bottom, */
.PDTitle2 .react-flow__handle-bottom,
.DTitle .react-flow__handle-bottom,
.DTitle2 .react-flow__handle-bottom,
.RPTitle .react-flow__handle-bottom,
.RPTitle2 .react-flow__handle-bottom,
.RFTitle .react-flow__handle-bottom,
.OPSSTitle .react-flow__handle-bottom,
.RFTitle2 .react-flow__handle-bottom,
.OSTitle .react-flow__handle-bottom,
.OSTitle2 .react-flow__handle-bottom,
.CNITitle .react-flow__handle-bottom,
.CNITitle2 .react-flow__handle-bottom,
.SATitle .react-flow__handle-bottom,
.SATitle2 .react-flow__handle-bottom,
.FRAPTitle .react-flow__handle-bottom,
.FRAPTitle2 .react-flow__handle-bottom,
.ATDTitle .react-flow__handle-bottom,
.ATDTitle2 .react-flow__handle-bottom,
.CSTitle .react-flow__handle-bottom,
.CSTOSTitleitle .react-flow__handle-bottom,
.CSTitle2 .react-flow__handle-bottom,
.PTitle .react-flow__handle-bottom,
.PTitle2 .react-flow__handle-bottom,
.PREDTitle .react-flow__handle-bottom,
.PREDTitle2 .react-flow__handle-bottom,
.ETitle .react-flow__handle-bottom,
.ETitle2 .react-flow__handle-bottom,
.Border1 .react-flow__handle-bottom,
.Border2 .react-flow__handle-bottom,
.Border3 .react-flow__handle-bottom,
.Border4 .react-flow__handle-bottom,
.Border5 .react-flow__handle-bottom,
.Border6 .react-flow__handle-bottom,
.Border7 .react-flow__handle-bottom,
.STM1title .react-flow__handle-bottom,
.STM2title .react-flow__handle-bottom,
.STM3title .react-flow__handle-bottom,
.STM4title .react-flow__handle-bottom,
.STM5title .react-flow__handle-bottom,
.STM6title .react-flow__handle-bottom,
.PDTitle2 .react-flow__handle-top,
.DTitle .react-flow__handle-top,
.DTitle2 .react-flow__handle-top,
.RPTitle .react-flow__handle-top,
.RPTitle2 .react-flow__handle-top,
.RFTitle .react-flow__handle-top,
.OPSSTitle .react-flow__handle-top,
.RFTitle2 .react-flow__handle-top,
.OSTitle .react-flow__handle-top,
.OSTitle2 .react-flow__handle-top,
.CNITitle .react-flow__handle-top,
.CNITitle2 .react-flow__handle-top,
.SATitle .react-flow__handle-top,
.SATitle2 .react-flow__handle-top,
.FRAPTitle .react-flow__handle-top,
.FRAPTitle2 .react-flow__handle-top,
.ATDTitle .react-flow__handle-top,
.ATDTitle2 .react-flow__handle-top,
.CSTitle .react-flow__handle-top,
.CSTitle2 .react-flow__handle-top,
.RFTitle .react-flow__handle-top,
.RFTitle2 .react-flow__handle-top,
.PREDTitle .react-flow__handle-top,
.PREDTitle2 .react-flow__handle-top,
.PTitle .react-flow__handle-top,
.PTitle2 .react-flow__handle-top,
.ETitle .react-flow__handle-top,
.ETitle2 .react-flow__handle-top,
.Border1 .react-flow__handle-top,
.Border2 .react-flow__handle-top,
.Border3 .react-flow__handle-top,
.Border4 .react-flow__handle-top,
.Border5 .react-flow__handle-top,
.Border6 .react-flow__handle-top,
.Border7 .react-flow__handle-top,
.STM1title .react-flow__handle-top,
.STM2title .react-flow__handle-top,
.STM3title .react-flow__handle-top,
.STM4title .react-flow__handle-top,
.STM5title .react-flow__handle-top,
.STM6title .react-flow__handle-top {
  visibility: hidden !important;
}

.J1title,
.I1title,
.H1title,
.G1title,
.F1title,
.E1title,
.D1title,
.C1title,
.B1title,
.A1title,
.group3,
.group2,
.group1,
.A1,
.B1,
.C1,
.D1,
.E1,
.F1,
.G1,
.H1,
.I1,
.J1,
.J2title,
.I2title,
.H2title,
.G2title,
.F2title,
.E2title,
.D2title,
.C2title,
.B2title,
.A2title,
.A2,
.B2,
.C2,
.D2,
.E2,
.F2,
.G2,
.H2,
.I2,
.J2,
.J3title,
.I3title,
.H3title,
.G3title,
.F3title,
.E3title,
.D3title,
.C3title,
.B3title,
.A3title,
.A3,
.B3,
.C3,
.D3,
.E3,
.F3,
.G3,
.H3,
.I3,
.J3,
.J4title,
.I4title,
.H4title,
.G4title,
.F4title,
.E4title,
.D4title,
.C4title,
.B4title,
.A4title,
.A4,
.B4,
.C4,
.D4,
.E4,
.F4,
.G4,
.H4,
.I4,
.J4,
.J5title,
.I5title,
.H5title,
.G5title,
.F5title,
.E5title,
.D5title,
.C5title,
.B5title,
.A5title,
.A5,
.B5,
.C5,
.D5,
.E5,
.F5,
.G5,
.H5,
.I5,
.J5,
.J6title,
.I6title,
.H6title,
.G6title,
.F6title,
.E6title,
.D6title,
.C6title,
.B6title,
.A6title,
.A6,
.B6,
.C6,
.D6,
.E6,
.F6,
.G6,
.H6,
.I6,
.J6,
/* .J7title,
.I7title,
.H7title,
.G7title,
.F7title,
.E7title,
.D7title,
.C7title,
.B7title,
.A7title,
.A7,
.B7,
.C7,
.D7,
.E7,
.F7,
.G7,
.H7,
.I7,
.J7,
.Border7, */
.PMDDTitle,
.MSATitle,
.TMRRTitle,
.EMDISDTitle,
.EMDSCMPDTitle,
.PDTitle,
.OSTitle,
.PDTitle,
.DTitle,
.PTitle,
.ETitle,
.CTitle,
.CNITitle,
.SATitle,
.SATitle2,
.FRAPTitle,
.ATDTitle,
.CSTitle,
.RFTitle,
.OPSSTitle,
.RPTitle,
.PREDTitle,
.Border1,
.Border2,
.Border3,
.Border4,
.Border5,
.Border6,
.Border7,
.STM1title,
.STM2title,
.STM3title,
.STM4title,
.STM5title,
.STM6title {
  border-radius: 0 !important;
}

.rowDivider {
  cursor: default !important;
}

.activityNode {
  width: 150px;
}

/* .react-flow__node, .react-flow__node-default {
  padding: 7px !important;
} */

/* .react-flow__controls-zoomin[title] {
  color: red !important;
} */
