body {
  font-family: Arial, Helvetica, sans-serif;
}

.navbar {
  overflow: hidden;
  background: #2a2a2a;
}

.editbar {
  overflow: hidden;
  background: #404040;
  position: absolute;
  z-index: 6;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
  border-bottom: 3px solid black;
  /* justify-content: space-between; */
}

.editTitle {
  /* border: 1px solid #ffffe0; */
  position: absolute;
  display: flex;
  padding: 3px 5px 3px 5px;
  margin-left: 43%;
  align-items: center;
  color: white;
  font-size: 14px;
}

.toolSectionColumn {
  display: flex;
  flex-direction: column;
  margin-left: 1vw;
}

.doneButton {
  /* margin-left: auto; */
  margin-right: 20px;
  border: 1px solid darkgray;
  padding: 3px 5px 3px 5px;
  cursor: default;
  text-align: end;
  height: 18px;
  align-items: center;
  border-radius: 5px;
  color: white;
  background-color: darkgray;
  opacity: 0;
}

.navbar a {
  float: left;
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  cursor: pointer;
}

.dropdown .columnStack {
  /* font-size: 16px; */
  border: none;
  outline: none;
  color: white;
  /* padding: 14px 16px; */
  /* padding-left: 16px;
  padding-right: 16px; */
  padding-left: 1vw;
  /* padding-top: 4px; */
  /* margin-right: auto; */
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.dropdown .columnStackProfile {
  /* font-size: 16px; */
  border: none;
  outline: none;
  color: white;
  /* padding: 14px 16px; */
  /* padding-left: 16px;
  padding-right: 16px; */
  padding-left: 1vw;
  /* margin-right: auto; */
  /* padding-top: 4px; */
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.dropdown .dropbtntools {
  border: none;
  outline: none;
  color: white;
  /* padding: 14px 16px; */
  background-color: inherit;
  margin: 0;
  /* margin-left: auto; */
  margin-right: auto;
  cursor: pointer;
  margin-left: 1vw;
  display: flex;
  flex-direction: column;
}

.dropdown .dropbtntoolsX {
  border: none;
  outline: none;
  color: white;
  /* padding: 14px 16px; */
  background-color: inherit;
  margin: 0;
  /* margin-left: auto; */
  margin-right: auto;
  cursor: pointer;
  margin-left: 1.55vw;
  display: flex;
  flex-direction: column;
}

/* .navbar a:hover,
.dropdown:hover .dropbtn {
} */

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
}

.help-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  margin-left: -104px;
  margin-top: 0.7px;
}

.settings-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  margin-left: -112px;
  margin-top: 0px;
}

.files-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 8;
  margin-left: 3px;
}

.addFeature-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 8;
  margin-left: 3px;
}

.notifications-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 8;
  margin-left: -22px;
}

.smallMenu-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  margin-left: -153px;
}

.tools-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  margin-left: -50px;
}

.search-dropdown-content {
  position: absolute;
  background-color: white;
  width: 240px;
  border: 1px solid grey;
  box-shadow: 0 0 2px 2px grey;
  z-index: 10;
}

.dropdown-content a,
.search-dropdown-content a,
.projectPopup a,
.help-content a,
.settings-content a,
.notifications-content a,
.smallMenu-content a,
.tools-content a,
.files-content a,
.addFeature-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover,
.search-dropdown-content .search-dropdown-content-hover:hover,
.help-content a:hover,
.settings-content a:hover,
.notifications-content a:hover,
.smallMenu-content a:hover,
.tools-content a:hover,
.files-content a:hover,
.addFeature-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content,
.dropdown:hover .search-dropdown-content,
.dropdown:hover .help-content,
.dropdown:hover .settings-content,
.dropdown:hover .notifications-content,
.dropdown:hover .smallMenu-content,
.dropdown:hover .tools-content,
.dropdown:hover .files-content,
.dropdown:hover .addFeature-content {
  display: block;
}

.navbar .search-container {
  float: right;
}

.navbar input[type="text"] {
  padding: 6px;
  margin-top: 8px;
  font-size: 17px;
  border: none;
}

input:focus {
  outline: none;
}

.navbar .search-container .submit {
  float: right;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 100px;
  background: #2a2a2a;
  color: white;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.navbar .search-container .gearQuestion {
  float: right;
  padding: 6px 10px;
  margin-top: 8px;
  margin-right: 10px;
  background: #2a2a2a;
  color: white;
  font-size: 17px;
  border: none;
  cursor: pointer;
}

.settingPopup,
.helpPopup,
.projectPopup,
.notificationsPopup,
.smallMenuPopup,
.toolsPopup,
.filesPopup,
.addFeaturePopup {
  background: white;
  border: 1px solid grey;
  box-shadow: 0 0 2px 2px grey;
}

.settingPopup a,
.helpPopup a,
.projectPopup a,
.notificationsPopup a,
.smallMenuPopup a,
.toolsPopup a,
.filesPopup a,
.addFeaturePopup a {
  cursor: pointer;
}

.settingPopup a:hover,
.helpPopup a:hover,
.projectPopup a:hover,
.notificationsPopup a:hover,
.smallMenuPopup a:hover,
.toolsPopup a:hover,
.filesPopup a:hover,
.addFeaturePopup a:hover {
  background-color: lightgrey;
}

.searchBox {
  background-image: url("../../lib/assets/img/Search.svg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 11.5vw;
}

.extraDropFont {
  font-size: "12px";
}

/* @media screen and (max-width: 600px) {
  .navbar .search-container {
    float: none;
  }
  .navbar a,
  .navbar input[type="text"],
  .navbar .search-container button {
    float: none;
    display: block;
    text-align: left;
    width: 100%;
    margin: 0;
    padding: 14px;
  }
  .navbar input[type="text"] {
    border: 1px solid #ccc;
  }
  .navbar .search-container .gearQuestion {
    display: none;
  }
} */

.switch {
  position: relative;
  display: flex;
  width: 50px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.35s;
  transition: 0.35s;
  z-index: 10 !important;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: 0px;
  background-color: white;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.navbarIcons {
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  margin-top: 8px;
}

.navbarColumnText {
  margin-left: auto;
  margin-right: auto;
  font-size: 9px;
  color: white;
  margin-top: 4px;
  padding-bottom: 4px;
}

.toolSectionIcons {
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  margin-top: 4px;
  padding-bottom: 1px;
}

.toolSectionIconsX {
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  margin-top: 4px;
  transform: rotate(45deg);
}

.toolSectionColumnTextX {
  margin-left: auto;
  margin-right: auto;
  font-size: 9px;
  color: white;
}

.toolSection {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
}

.toolSectionColumnText {
  margin-left: auto;
  margin-right: auto;
  font-size: 9px;
  color: white;
  margin-top: 4px;
  padding-bottom: 4px;
}

.toolSectionColumnTextHolder {
  margin-left: auto;
  margin-right: auto;
  font-size: 9px;
  color: #2a2a2a;
  margin-top: 4px;
}

.smallMenu {
  display: none;
}
.hideMenus {
  display: block;
}

.lds-dual-ring-navbar {
  display: inline-block;
  width: 20px;
  height: 20px;
}
.lds-dual-ring-navbar:after {
  content: " ";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
  margin-left: 10px;
  margin-top: 2px;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 960px) {
  .smallMenu {
    display: block;
  }
  .hideMenus {
    display: none;
  }
}

@media (max-width: 840px) {
  .editTitle {
    /* border: 1px solid #ffffe0; */
    position: absolute;
    display: flex;
    padding: 3px 5px 3px 5px;
    margin-left: 55%;
    align-items: center;
    color: white;
    font-size: 14px;
  }
}
