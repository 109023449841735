.modal-Reportbody {
  /* max-height: 27vh; */
  /* overflow-y: auto; */
  /* padding-bottom: 20px; */
}

.modal-Reportbody::-webkit-scrollbar {
  display: block !important;
  /* padding-right: 18px !important; */
  width: 0 !important;
}

.overFlowTextActivity {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.reportRowHeaders {
  background-color: #047cc0;
  width: 98vw;
  /* max-width: 1804.8px; */
  height: 3vh;
  display: flex;
  align-items: center;
}

.responsiveReportText {
  font-size: 1.65vh;
  text-align: center;
}

@media (max-width: 1770px) {
  .modal-Reportbody {
    /* height: 26vh; */
    overflow-y: auto;
    /* padding-bottom: 20px; */
  }
}
@media (max-width: 1590px) {
  .modal-Reportbody {
    /* height: 25vh; */
    overflow-y: auto;
    /* padding-bottom: 20px; */
  }
}
@media (max-width: 1490px) {
  .modal-Reportbody {
    /* height: 24vh; */
    overflow-y: auto;
    /* padding-bottom: 20px; */
  }
}
@media (max-width: 1440px) {
  .modal-Reportbody {
    /* height: 23vh; */
    overflow-y: auto;
    /* padding-bottom: 20px; */
  }
}
@media (max-width: 1390px) {
  .modal-Reportbody {
    /* height: 21vh; */
    overflow-y: auto;
    /* padding-bottom: 20px; */
  }
}
@media (max-width: 1275px) {
  .responsiveReportText {
    font-size: 11px;
  }
}
@media (max-width: 1320px) {
  .modal-Reportbody {
    /* height: 19vh; */
    overflow-y: auto;
    /* padding-bottom: 20px; */
  }
}
@media (max-width: 1270px) {
  .modal-Reportbody {
    /* height: 17vh; */
    overflow-y: auto;
    /* padding-bottom: 20px; */
  }
}
@media (max-width: 1220px) {
  .modal-Reportbody {
    /* height: 15vh; */
    overflow-y: auto;
    /* padding-bottom: 20px; */
  }
}
@media (max-width: 1120px) {
  .modal-Reportbody {
    /* height: 13vh; */
    overflow-y: auto;
    /* padding-bottom: 20px; */
  }
}
@media (max-width: 1040px) {
  .modal-Reportbody {
    /* height: 11vh; */
    overflow-y: auto;
    /* padding-bottom: 20px; */
  }
}
@media (max-width: 950px) {
  .modal-Reportbody {
    /* height: 8vh; */
    overflow-y: auto;
    /* padding-bottom: 20px; */
  }
}
@media (max-width: 865px) {
  .modal-Reportbody {
    /* height: 6vh; */
    overflow-y: auto;
    /* padding-bottom: 20px; */
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  /* .reportTypeInfo *, */
  /* .summarChart *, */
  .reportTableSection * {
    visibility: visible;
  }
  .reportTableSection {
    position: absolute;
    left: 0;
    top: 0;
  }
}
