/* .openMenuMap {
  position: absolute !important;
  left: 5 !important;
}

.closeMenuMap {
  position: absolute !important;
  left: auto !important;
} */

.unSelect {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
.itemsidenav {
  /* height: 873px; */
  height: calc(100% - 49px);
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
  /* todo */
  transition: 0.5s;
  margin-top: 49px;
}

.itemsidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.itemsidenav a:hover {
  color: #f1f1f1;
}

.itemsidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.noActivityText {
  font-size: 20px;
}

.tabcontent {
  background-color: white;
  height: 10vh
}

.dropdownStatus {
  float: left;
  overflow: hidden;
}

.dropdownStatus .dropbtnStatus {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  /* padding: 14px 16px; */
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  padding-top: 13px;
  padding-bottom: 14px;
}

.activityPopupText {
  font-size: 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 10px;
}

.activityPopupOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.activity-content {
  display: none;
  position: absolute;
  width: 210px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 8;
  margin-left: 10px;
}

.hideContent {
  display: none;
  position: absolute;
  width: 210px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 8;
  margin-left: 10px;
}

.activity-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 16px;
}

.activity-content .activityPopupOptions:hover {
  background-color: #ddd !important;
}

.dropdownStatus:hover .activity-content {
  display: block;
}

.activityPopup {
  background: white;
  border: 1px solid grey;
  box-shadow: 0 0 2px 2px grey;
}

.activityPopup a {
  cursor: pointer;
}

.activityPopup a:hover {
  background-color: lightgrey;
}

.react-datepicker__input-container input {
  height: 20px;
  width: 110px;
  margin-left: 15px;
}

.react-datepicker__input-container,
.react-datepicker-wrapper {
  width: 140px !important;
}

.detailItemText,
.libraryItemsText,
.managementItemText {
  font-size: 14px;
  margin-top: 14px;
  margin-bottom: 14px;
}

@media screen and (max-height: 1080px) {
  .CancelSaveSectionBuffer {
    padding-bottom: 5vh !important;
  }
}

@media screen and (max-height: 960px) {
  .CancelSaveSection {
    padding-bottom: 13vh !important;
  }
  .CancelSaveSectionBuffer {
    padding-bottom: 20vh !important;
  }
}

/* @media (max-height: 1920px) {
  .CancelSaveSection {
    padding-bottom: 0px;
  }
}
@media (max-height: 1880px) {
  .CancelSaveSection {
    padding-bottom: 80px;
  }
} */

.itemDesc {
  white-space: pre-wrap;
}
