.sidenav {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
  overflow-x: hidden;
  /* todo */
  transition: 0.5s;
  /* padding-top: 0px; */
  margin-top: 49px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}
