.rightMenu {
    background: #e1e2e1;
    border: 1px solid grey;
    /* box-shadow: 0 0 2px 2px grey; */
  }
  
  .rightMenu div {
    cursor: pointer;
    background-color: #e1e2e1;
    float: none;
    color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    text-decoration: none;
    display: block;
    /* text-align: left; */
    padding-left: 10px;
  }
  
  .rightMenu div:hover {
    background-color: #e7f4ff;
  }
  