.modal-container-acronym {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
}

.delete-modal-container-acronym {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
}

.projectModals-acronym {
  margin: auto;
  /* width: 90%; */
  background-color: white;
  border-radius: 8px;
  /* width: 46vw;
  height: 40vh; */
  border: 1.5px solid black;
  padding: 20px;
}

.modal-header,
.modal-content {
  padding: 1.5rem;
}

.modal-body {
  height: 250px;
  overflow-y: auto;
}

.overFlowTextProject.responsiveFont:hover {
  color: blue;
  text-decoration: underline;
}

.acronymSearchBox {
  background-image: url("../../lib/assets/img/Search.svg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: 20px;
}

.overFlowTextAcronym.responsiveFont,
.responsiveFont,
.fa.fa-pencil.responsiveFont {
  font-size: 0.75vw;
}

@media (max-width: 1265px) {
  .overFlowTextAcronym.responsiveFont,
  .responsiveFont,
  .fa.fa-pencil.responsiveFont {
    font-size: 10px;
  }
  .modalPencil {
    height: 13px;
    width: 13px;
  }
}
