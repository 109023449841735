.newPMInputs {
  width: 600px;
  height: 200px;
  resize: none;
  outline: none;
}

.modal-body {
  height: 250px;
  overflow-y: auto;
}

/* .overFlowTextDIDS {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; 
  -webkit-box-orient: vertical;
} */

.didsSearchBox {
  background-image: url("../../lib/assets/img/Search.svg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: 20px;
}

.overFlowTextDIDS.responsiveFont,
.responsiveFont,
.fa.fa-pencil.responsiveFont {
  font-size: 0.75vw;
}

.overFlowTextDIDS.responsiveFont.linkText:hover {
  color: blue;
  text-decoration: underline;
}

@media (max-width: 1265px) {
  .overFlowTextDIDS.responsiveFont,
  .responsiveFont,
  .fa.fa-pencil.responsiveFont {
    font-size: 10px;
  }
}
