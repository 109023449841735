.newPMInputs {
  width: 600px;
  height: 200px;
  resize: none;
  outline: none;
}
.lds-dual-ring2 {
  display: inline-block;
  width: 15px;
  height: 15px;
}
.lds-dual-ring2:after {
  content: " ";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
