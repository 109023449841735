.modal-container-reference {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
}

.delete-modal-container-reference {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
}

.projectModals-reference {
  margin: auto;
  /* width: 90%; */
  background-color: white;
  border-radius: 8px;
  /* width: 46vw;
  height: 40vh; */
  border: 1.5px solid black;
  padding: 20px;
}

.projectModals-reference-popupBox {
  margin: auto;
  /* width: 90%; */
  background-color: white;
  border-radius: 8px;
  /* width: 46vw;
  height: 40vh; */
  border: 1.5px solid black;
  padding: 20px;
  height: 420px;
  overflow-y: auto;
}

.modal-header,
.modal-content {
  padding: 1.5rem;
}

.modal-body {
  height: 250px;
  overflow-y: auto;
}

/* .overFlowTextReference {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
} */

.referenceSearchBox {
  background-image: url("../../lib/assets/img/Search.svg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: 20px;
}

.overFlowTextReference.responsiveFont,
.responsiveFont,
.fa.fa-pencil.responsiveFont {
  font-size: 0.75vw;
}

.linkText:hover {
  color: blue;
  text-decoration: underline;
}

@media (max-width: 1270px) {
  .overFlowTextReference.responsiveFont,
  .responsiveFont,
  .fa.fa-pencil.responsiveFont {
    font-size: 10px;
  }
}
