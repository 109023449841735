.dashboardCards {
  width: 11vw;
  height: 20vh;
}

.dashboardCardsImg {
  width: 11vw;
  height: 12vh;
}

.dashboardCardsOpenTXT {
  font-size: 0.7vw;
}

.dashboardCardsStar {
  font-size: 0.8vw;
}

.overFlowCardText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  font-size: 0.7vw;
}
.center {
  display: flex;
  height: 4vh;
  /* align-items: center; */
  padding-left: 0.3vw;
  padding-right: 0.3vw;
  margin-top: 2px;
}
.center2 {
  display: flex;
  height: 3vh;
  align-items: center;
  padding-left: 0.3vw;
  padding-right: 0.3vw;
}

@media (max-width: 1230px) {
  .dashboardCards {
    width: 135px;
    height: 120px;
  }
  .dashboardCardsImg {
    width: 135px;
    height: 69px;
  }
  .overFlowCardText {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 8.6px;
  }
  .center {
    display: flex;
    height: 21.5px;
    /* align-items: center; */
    padding-left: 0.3vw;
    padding-right: 0.3vw;
    margin-top: 2px;
  }
  .center2 {
    display: flex;
    height: 16.18px;
    align-items: center;
    padding-left: 0.3vw;
    padding-right: 0.3vw;
  }
  .dashboardCardsOpenTXT {
    font-size: 8.61px;
  }
  .dashboardCardsStar {
    font-size: 9.84px;
  }
}
