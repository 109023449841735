/* .mainTitle {
  font-size: 3vh;
  margin: 21.44px 0px;
} */

.inputText {
  width: 350px;
  height: 25px;
  border-radius: 5px;
}
.firstText {
  margin-bottom: 20px;
}

.forgotP {
  cursor: pointer;
}

.submitB {
  width: 355px;
  align-self: center;
  /* background-color: rgb(11, 11, 207); */
  background-color: blue;
  color: white;
  border: none;
  padding: 5px;
  font-size: 14px;
  margin: 4px 2px;
  border-radius: 8px;
  height: 30px;
}

.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loginList,
.loginListItem {
  color: #fff;
}

.loginListItem {
  margin: 15px;
}

.DODTitle,
.DODText {
  font-size: 1.6vh;
}

.loginListItem {
  font-size: 1.5vh;
}
