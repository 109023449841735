.newPMInputs {
  width: 600px;
  height: 200px;
  resize: none;
  outline: none;
}

.modal-body {
  height: 250px;
  overflow-y: auto;
}

/* .overFlowTextTools {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10; 
  -webkit-box-orient: vertical;
} */

.toolSearchBox {
  background-image: url("../../lib/assets/img/Search.svg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: 20px;
}

.overFlowTextTools.responsiveFont,
.responsiveFont,
.fa.fa-pencil.responsiveFont {
  font-size: 0.75vw;
}

@media (max-width: 1265px) {
  .overFlowTextTools.responsiveFont,
  .responsiveFont,
  .fa.fa-pencil.responsiveFont {
    font-size: 10px;
  }
}
