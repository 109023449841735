.reportRadios {
  vertical-align: middle;
  margin-top: -1px;
}

@media (max-height: 880px) {
  .typeTexts {
    font-size: 12px;
  }
}

.responsiveFont,
.fa.fa-pencil.responsiveFont {
  font-size: 0.75vw;
}

.responsiveFontHeader{
  font-size: 0.8vw;
}

/* .modalPencil{
  height: ;
} */

@media (max-width: 1265px) {
  .overFlowTextAcronym.responsiveFont,
  .responsiveFont,
  .fa.fa-pencil.responsiveFont {
    font-size: 10px;
  }
  .modalPencil {
    height: 13px;
    width: 13px;
  }
  .responsiveFontHeader{
    font-size: 11px;
  }
}