.newPMInputs {
  width: 600px;
  height: 200px;
  resize: none;
  outline: none;
}

.modal-body{
  height: 250px;
  overflow-y: auto;
}