.newPMInputs {
  width: 600px;
  height: 200px;
  resize: none;
  outline: none;
}

.modal-body-acronym {
  height: 250px;
  overflow-y: auto;
}

/* .overFlowTextAcronym {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
} */

.acronymSearchBox {
  background-image: url("../../lib/assets/img/Search.svg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: 20px;
}

.overFlowTextAcronym.responsiveFont,
.responsiveFont,
.fa.fa-pencil.responsiveFont {
  font-size: 0.75vw;
}

/* .modalPencil{
  height: ;
} */

@media (max-width: 1265px) {
  .overFlowTextAcronym.responsiveFont,
  .responsiveFont,
  .fa.fa-pencil.responsiveFont {
    font-size: 10px;
  }
  .modalPencil {
    height: 13px;
    width: 13px;
  }
}
