.reportBoxes {
  height: 67vh;
  width: 25vw;
  border-radius: 2.9px;
}

.bottomRowArea {
  padding-right: 6%;
}

.checkboxSections {
  height: 83%;
  overflow-y: auto;
}

.extraScroll {
  height: 100%;
}

.stepTwoText {
  padding-left: 6vw;
}

/* @media (max-width: 1900px) {
  .stepTwoText {
    padding-left: 55px;
  }
}
@media (max-width: 1800px) {
  .stepTwoText {
    padding-left: 52px;
  }
} */
@media (max-width: 1385px) {
  .reportBoxes {
    height: 62vh;
    width: 25vw;
  }
  .checkboxSections {
    height: 80%;
  }
}
@media (max-width: 1270px) {
  .reportBoxes {
    height: 53vh;
    width: 25vw;
  }
  .checkboxSections {
    height: 76%;
  }
}
@media (max-width: 1035px) {
  .reportBoxes {
    height: 45vh;
    width: 25vw;
  }
  .checkboxSections {
    height: 74%;
    overflow-y: hidden;
  }
}
@media (max-height: 550px) {
  .extraScroll {
    overflow-y: scroll;
  }
  .bottomRowArea {
    margin-top: 0.5%;
    padding-bottom: 10%;
  }
}
/* @media (max-height: 480px) {
  .extraScroll {
    overflow-y: scroll;
  }
  .bottomRowArea {
    margin-top: 0.5%;
    padding-bottom: 10%;
  }
} */
