.snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: transparent;
  color: transparent;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

.snackbar.show {
  visibility: visible;
  background-color: #333;
  color: #fff;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
  animation: fadein 0.5s, fadeout 0.5s 4.5s;
}

.snackbar2 {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: transparent;
  color: transparent;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

.snackbar2.show {
  visibility: visible;
  background-color: #333;
  color: #fff;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
  animation: fadein 0.5s, fadeout 0.5s 4.5s;
}

.snackbar3 {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: transparent;
  color: transparent;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  position: fixed;
  z-index: 9;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

.snackbar3.show {
  visibility: visible;
  background-color: #333;
  color: #fff;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
  animation: fadein 0.5s, fadeout 0.5s 4.5s;
}

.snackbar4 {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: transparent;
  color: transparent;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  position: fixed;
  z-index: 9;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

.snackbar4.show {
  visibility: visible;
  background-color: #333;
  color: #fff;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
  animation: fadein 0.5s, fadeout 0.5s 4.5s;
}

.snackbar5 {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: transparent;
  color: transparent;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  position: fixed;
  z-index: 9;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

.snackbar5.show {
  visibility: visible;
  background-color: #333;
  color: #fff;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
  animation: fadein 0.5s, fadeout 0.5s 4.5s;
}

.snackbar6 {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: transparent;
  color: transparent;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  position: fixed;
  z-index: 1001;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

.snackbar6.show {
  visibility: visible;
  background-color: #333;
  color: #fff;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
  animation: fadein 0.5s, fadeout 0.5s 4.5s;
}

.snackbar7 {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: transparent;
  color: transparent;
  text-align: center;
  border-radius: 8px;
  padding: 16px;
  position: fixed;
  z-index: 1001;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

.snackbar7.show {
  visibility: visible;
  background-color: #333;
  color: #fff;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4.5s;
  animation: fadein 0.5s, fadeout 0.5s 4.5s;
}
