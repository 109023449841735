.box2 {
  display: flex;
  /* align-items: stretch; */
}

/* .box {
  display: flex;
}

.box3 {
  display: flex;
  flex-direction: column;
} */

.box {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.leftSide {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* border: 5px solid red; */
}

.header2 {
  display: flex;
  flex-wrap: wrap;
  /* overflow-y: auto;
  max-height: 45vh */
  /* flex-direction: row; */
  /* border: 5px solid red; */
  /* height: 100vh; */
  /* width: 100vw; */
  padding-right: 20px;
}

.header3 {
  display: flex;
  flex-direction: row;
}

.subTitle {
  height: 50px;
  width: 100%;
  border-bottom: 2px solid rgb(236, 236, 236);
  align-items: center;
  cursor: default;
}

.push {
  margin-left: auto;
  cursor: pointer;
  padding-right: 40px;
}

.projectListItems {
  padding: 10px;
  cursor: pointer;
  margin-left: -40px;
}

.projectListItems:hover {
  background-color: lightgrey;
}

.main {
  overflow: hidden !important;
  height: 100vh;
}
