.text-updater-node {
  width: 150px;
  overflow: clip;
  max-height: 163px;
  border: 1px solid lightgray;
  padding: 5px;
  /* border-radius: 5px; */
  background: white;
  /* border: 1px solid lightgray; */
  box-shadow: 0 4px 8px 0 lightgray;
  text-align: center;
}

.text-updater-node-STM {
  width: 150px;
  overflow: clip;
  max-height: 80px;
  border: 1px solid lightgray;
  padding: 5px;
  /* border-radius: 5px; */
  background: white;
  /* border: 1px solid lightgray; */
  box-shadow: 0 4px 8px 0 lightgray;
  text-align: center;
}

.text-updater-node label {
  display: block;
  color: #777;
  font-size: 12px;
}

.text-updater-node-STM label {
  display: block;
  color: #777;
  font-size: 12px;
}

.summaryText {
  color: #777;
  margin: 0.75px 0px;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 13; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.summaryText-STM {
  color: #777;
  margin: 0.75px 0px;
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.diamond-shape {
  background: white;
  height: 20px;
  text-align: center;
  transform: rotate(45deg);
  width: 20px;
  border: 1px solid black;
}
.diamond-centered-text {
  color: black;
  /* display: table-cell; */
  /* height: 67px; */
  /* transform: rotate(-45deg); */
  /* vertical-align: middle; */
  /* width: 67px; */
  font-size: 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  -webkit-box-orient: vertical;
}
