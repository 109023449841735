.modal-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
}

.modal {
  margin: auto;
  /* width: 90%; */
  background-color: white;
  border-radius: 8px;
  /* width: 46vw;
  height: 40vh; */
  border: 1.5px solid black;
  padding: 20px;
}

.modal-header,
.modal-content {
  padding: 1.5rem;
}

.modal-body {
  height: 250px;
  overflow-y: auto;
}
