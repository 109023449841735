.tableHeaders {
  background-color: rgb(240, 238, 238);
}

.tableRowData:hover {
  background-color: #047cc0;
  color: white !important;
}

.rowIcon {
  visibility: hidden;
}

.tableRowData:hover .rowIcon {
  visibility: visible !important;
  font-size: 0.825vw;
}

.TableRowMenuItems {
  padding: 10px;
  cursor: pointer;
  margin-left: -40px;
  color: black;
}

.TableRowMenuItems:hover {
  background-color: lightgrey;
}

.responsiveTableFont {
  font-size: 0.825vw;
}

@media (max-width: 1265px) {
  .responsiveTableFont {
    font-size: 10px;
  }
}
