.descInputs {
  width: 550px;
  height: 15vh;
  resize: none;
  outline: none;
}

.feedbackRadios {
  vertical-align: middle;
  margin-top: -1px;
}
