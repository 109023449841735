.sidebar {
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
}

.projectButtons {
  width: 70%;
  align-self: center;
  border: none;
  color: white;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

.sideBarFont {
  font-size: 16px;
}

.orderedList {
  list-style: none;
}

.sideList {
  padding: 0.3vh;
  cursor: pointer;
  align-items: center;
}

.sideMeta {
  padding: 0.4vh;
}

.sideList:hover {
  border-left: 4px solid #047cc0 !important;
}

.listName {
  margin-left: 15px;
}

.sidebarIcons {
  height: 18px;
  width: 18px;
}

@media (max-height: 800px) {
  .sideBarFont {
    font-size: 2vh;
  }
  .projectButtons {
    font-size: 1.7vh;
  }
  .sidebarIcons {
    height: 2vh;
    width: 2vh;
  }
}
@media (max-height: 700px) {
  .sideBarFont {
    font-size: 13px;
  }
  .projectButtons {
    font-size: 11px;
  }
  .sidebarIcons {
    height: 12px;
    width: 12px;
  }
}
